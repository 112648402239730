import { GridProps, forwardRef, Grid } from '@chakra-ui/react';
import { padding } from '@theme';
import React from 'react';

export interface Grid8Props extends GridProps {
	defaultPadding?: boolean;
	flush?: boolean | 'both' | 'vertical' | 'horizontal';
	isDense?: boolean;
	isLarge?: boolean;
	rowSpacing?: 'equal' | 'flush';
}

export const Grid8 = forwardRef<Grid8Props, 'div'>(({ isLarge, flush, rowSpacing, isDense, children, defaultPadding = true, ...props }, ref) => {
	const rowGap = React.useMemo(() => {
		if (rowSpacing == 'equal') {
			return 4;
		} else if (rowSpacing == 'flush') {
			return 0;
		} else {
			return isLarge ? { base: 20, sm: 12, md: 24 } : 8;
		}
	}, [isLarge, rowSpacing]);

	const gridProps: GridProps = {
		templateColumns: 'repeat(8, minmax(0, 1fr))',
		columnGap: 4,
		rowGap,
		mb: isLarge ? [4, 12, 24] : 0,
		// gridAutoFlow: isDense && { base: 'dense', md: 'unset' }
	};

	const pad: GridProps = React.useMemo(() => {
		if (!defaultPadding) return {};
		else if (flush == 'vertical') return { px: padding } as GridProps;
		else if (flush == 'horizontal') return { py: padding } as GridProps;
		else if (flush == 'both' || flush) return { p: padding } as GridProps;
		else return {};
	}, [flush]);

	return (
		<Grid
			className="grid-8"
			//minHeight={[4, 12, 56]}
			{...pad}
			{...gridProps}
			ref={ref}
			{...props}
		>
			{children}
		</Grid>
	);
});

Grid8.defaultProps = {
	flush: true,
};

export type GridCellSize = 'span1' | 'span2' | 'span3' | 'span4' | 'span5' | 'span6' | 'span7' | 'span8' | 'full';
export type GridColumns = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | string;
