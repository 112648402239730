import { Link } from '@chakra-ui/react';
import * as React from 'react';

export const ExternalLink: React.FC<{ href: string }> = (props) => {
	return <Link href={props.href}>{props.children}</Link>;
};

export const ExternalLinkWrapper: React.FC<{ href: string }> = (props) =>
	props.href ? <ExternalLink href={props.href}>{props.children}</ExternalLink> : <>{props.children}</>;

export default ExternalLink;
